import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import WithEmptyFeedback from "components/core/WithEmptyFeedback";
import Pagination from "components/core/Pagination";
import {
  Table,
  TableBody,
  TableCheckbox,
  TableHeader,
  TableHeaderItem,
  TableItem,
  RenderHeaders,
} from "components/core/Table";
import { formatDate } from "helpers/format";
import useDocumentsTypes from "hooks/useDocumentsTypes";

const DocumentsList = ({
  documents,
  params,
  total,
  totalPages,
  page,
  perPage,
  handleChange,
  handleShowDocument,
  orderBy,
  order,
  toggleAll,
  checkedAll,
}) => {
  const { t: translate } = useTranslation();
  const headers = [
    { slug: "invoiceNumber", title: translate("fileName") },
    { slug: "type", title: translate("documentType") },
    { slug: "price", title: translate("competencyDate") },
    { slug: "createdAt", title: translate("sendDate") },
  ];

  const { getDocumentTypeLabel } = useDocumentsTypes();

  return (
    <>
      <Table>
        <TableHeader>
          <TableHeaderItem alignCenter width="5%">
            <TableCheckbox name="toggleAll" handleChange={toggleAll} checked={checkedAll} />
          </TableHeaderItem>
          <RenderHeaders
            headers={headers}
            setOrderBy={params.setOrderBy}
            orderBy={orderBy}
            order={order}
          />
        </TableHeader>
        <TableBody>
          {documents.map(({ name, createdAt, id, checked, referenceDate, idTypeDocument }) => (
            <tr key={id}>
              <TableItem textAlign="center">
                <TableCheckbox
                  name={`toggle_${id}`}
                  value={id}
                  handleChange={() => handleChange(id)}
                  checked={checked}
                />
              </TableItem>
              <TableItem handleClick={() => handleShowDocument(id)}>{name}</TableItem>
              <TableItem handleClick={() => handleShowDocument(id)}>
                {getDocumentTypeLabel(idTypeDocument) ?? "-"}
              </TableItem>
              <TableItem handleClick={() => handleShowDocument(id)}>
                {formatDate(referenceDate)}
              </TableItem>
              <TableItem handleClick={() => handleShowDocument(id)}>
                {formatDate(createdAt)}
              </TableItem>
            </tr>
          ))}
        </TableBody>
      </Table>
      <Pagination
        perPage={perPage}
        totalItems={total}
        actualPage={page}
        lastPage={totalPages}
        setPage={params.setPage}
      />
    </>
  );
};

DocumentsList.propTypes = {
  documents: PropTypes.array,
  params: PropTypes.object.isRequired,
  total: PropTypes.number,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
  handleShowDocument: PropTypes.func.isRequired,
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
  toggleAll: PropTypes.func.isRequired,
  checkedAll: PropTypes.bool.isRequired,
};

DocumentsList.defaultProps = {
  documents: [],
  total: 0,
  totalPages: 0,
  page: 1,
  perPage: 0,
};

export default WithEmptyFeedback(DocumentsList);
