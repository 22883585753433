import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import Public from "components/layout/Public";
import Payment from "components/layout/Payment";
import Private from "components/layout/Private";
import Register from "components/layout/Register";
import Landing from "components/layout/Landing";
import PublicRoutes from "routes/public";
import PaymentsRoutes from "routes/payment";
import CongratulationsRoutes from "routes/congratulations";
import NewUserRoutes from "routes/newUser";
import LandingRoutes from "routes/landing";
import { ROUTES, baseURL } from "utils/constants";
import { getAuthClaims, removeClaims } from "helpers";

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: ROUTES.login.path }} />
      )
    }
  />
);


const Routes = () => {
  const { isAuthenticated } = useSelector(({ auth }) => auth); 
  const { token } = getAuthClaims() ?? {};
  const expired = useRef(false);
  const [isLoading, setIsLoading] = useState(true);

  const verifyTokenExpired = async () => {
    try {
      const response = await fetch(`${baseURL}admin/verify-user`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (data.message === 'jwt-expired') {
        expired.current = true;
        removeClaims();
      }
    } catch (error) {
      console.error("Erro ao verificar o token:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      verifyTokenExpired();
    } else {
      setIsLoading(false);
    }
  }, [token]);

  return (
    <Switch>
      <Route path={ROUTES.payment.path} render={() => <Public routes={PaymentsRoutes} />} />
      <Route path={ROUTES.openApp.path} render={() => <Payment routes={PaymentsRoutes} />} />
      <Route path={ROUTES.invoicePayment.path} render={() => <Payment routes={PaymentsRoutes} />} />
      <Route
        path={ROUTES.invoicePaymentOld.path}
        render={() => <Public routes={PaymentsRoutes} />}
      />
      <Route
        path={ROUTES.congratulations.path}
        render={() => <Public routes={CongratulationsRoutes} />}
      />
      <Route path={ROUTES.newUser.path} render={() => <Register routes={NewUserRoutes} />} />
      <Route path={ROUTES.landingNewUser.path} render={() => <Landing routes={LandingRoutes} />} />
      <Route
        path={ROUTES.login.path}
        render={() =>
          isAuthenticated && token && !expired.current ? (
            <Redirect to={ROUTES.app.path} />
          ) : (
            <Public routes={PublicRoutes} />
          )
        }
      />

      {
        !isLoading && (
          <PrivateRoute
            path={ROUTES.app.path}
            component={Private}
            isAuthenticated={!expired.current ? isAuthenticated : false}
          />
        )
      }
    </Switch>
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default Routes;
