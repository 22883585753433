import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { ROUTES } from "utils/constants";
import Home from "containers/Home";
import Batches from "containers/Batches/List";
import BatchesConfirm from "containers/Batches/Confirm";
import BatchesConfirmEditEnterprise from "containers/Batches/Confirm/EditEnterprise";
import BatchesCompany from "containers/Batches/Company";
import BatchesDetails from "containers/Batches/Details";

const PrivateBatches = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={Home} />
      <Route exact path={ROUTES.batches.path} component={Batches} />
      <Route exact path={ROUTES.batches.children.confirm.path} component={BatchesConfirm} />
      <Route
        exact
        path={ROUTES.batches.children.confirm.children.editEnterprise.path}
        component={BatchesConfirmEditEnterprise}
      />
      <Route exact path={ROUTES.batches.children.company.path} component={BatchesCompany} />
      <Route exact path={ROUTES.batches.children.details.path} component={BatchesDetails} />
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default PrivateBatches;
